import "leaflet-plugin-trackplayback";
import L from "leaflet";

const getDivContainer = () => {
  const container = L.DomUtil.create(
    "div",
    "leaflet-bar leaflet-control leaflet-control-custom"
  );
  container.style.backgroundColor = "white";
  container.style.width = "230px";
  container.style.height = "42px";
  container.style.border = "1px solid black";
  container.style.marginTop = "100px";
  container.style.position = "relative";
  container.style.display = "block";

  return container;
};

const getInnerContainer = () => {
  const container = L.DomUtil.create("div");

  container.style.margin = "0";
  container.style.position = "absolute";
  container.style.top = "50%";
  container.style.transform = "translateY(-50%)";

  return container;
};

const actionIconStyle = {
  width: "32px",
  height: "32px",
  backgroundSize: "32px 32px",
  border: "0",
  backgroundColor: "transparent",
  margin: "5px 0 0 10px",
};

const getPlayButton = () => {
  const playButton = L.DomUtil.create("button");

  playButton.style["backgroundImage"] = "url(/assets/img/playIcon.png)";

  //style object is readonly so we can't assign a new object with the merged styles
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    playButton.style[key] = value;
  });

  return playButton;
};

const getPauseButton = () => {
  const pauseButton = L.DomUtil.create("button");

  pauseButton.style.backgroundImage = "url(/assets/img/pauseIcon.png)";
  pauseButton.style.display = "none";
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    pauseButton.style[key] = value;
  });

  return pauseButton;
};

const getRewindButton = () => {
  const rewindButton = L.DomUtil.create("button");

  rewindButton.style.backgroundImage = "url(/assets/img/replayIcon.png)";
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    rewindButton.style[key] = value;
  });

  return rewindButton;
};

const getSlowButton = () => {
  const slowButton = L.DomUtil.create("button");

  slowButton.style.backgroundImage = "url(/assets/img/slowIcon.png)";
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    slowButton.style[key] = value;
  });

  return slowButton;
};

const getFastButton = () => {
  const fastButton = L.DomUtil.create("button");

  fastButton.style.backgroundImage = "url(/assets/img/fastIcon.png)";
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    fastButton.style[key] = value;
  });

  return fastButton;
};

const getClearButton = () => {
  const clearButton = L.DomUtil.create("button");

  clearButton.style.backgroundImage = "url(/assets/img/clearIcon.png)";
  Object.entries(actionIconStyle).forEach(([key, value]) => {
    clearButton.style[key] = value;
  });

  return clearButton;
};

const getControlInstance = (
  mapInstance,
  track,
  playbackTickHandler,
  closePanel,
  updateMarkerJourney,
  onReady = () => {},
  onRemove = () => {}
) => {
  const trackOptions = {
    clockOptions: {
      speed: 6,
    },
    trackPointOptions: {
      useCanvas: false,
    },
    targetOptions: {
      useImg: true,
      imgUrl: "/assets/img/truckLeft.png",
      width: 32,
      height: 32,
      color: "#00f",
      fillColor: "#9FD12D",
    },
  };

  if (track && track.length > 0) {
    const trackplayback = L.trackplayback(track, mapInstance, trackOptions);

    trackplayback.on("tick", (e) => {
      const { time, target } = e;
      const matchPoint =
        target &&
        target.tracks &&
        target.tracks[0]._trackPoints.filter(
          (p) => parseInt(p.time, 10) === parseInt(time, 10)
        );

      if (matchPoint && matchPoint.length > 0) {
        playbackTickHandler(matchPoint[0].info[0].value);
      }
    });

    const playControl = L.Control.extend({
      options: {
        position: "topright",
      },
      onAdd: () => {
        const divContainer = getDivContainer();
        const innerContainer = getInnerContainer();
        const playButton = getPlayButton();
        const pauseButton = getPauseButton();
        const rewindButton = getRewindButton();
        const slowButton = getSlowButton();
        const fastButton = getFastButton();
        const clearButton = getClearButton();

        playButton.onclick = () => {
          trackplayback.start();
          playButton.style.display = "none";
          pauseButton.style.display = "inline-block";
        };

        pauseButton.onclick = () => {
          trackplayback.stop();
          playButton.style.display = "inline-block";
          pauseButton.style.display = "none";
        };

        rewindButton.onclick = () => trackplayback.rePlaying();
        slowButton.onclick = () => trackplayback.slowSpeed();
        fastButton.onclick = () => trackplayback.quickSpeed();
        clearButton.onclick = () => {
          if (trackplayback) {
            //divContainer.remove();
            trackplayback.stop();
            trackplayback.dispose();
            updateMarkerJourney && updateMarkerJourney({});
            closePanel && closePanel();
          }
          divContainer.style.display = "none";
        };

        divContainer.appendChild(innerContainer);
        innerContainer.appendChild(playButton);
        innerContainer.appendChild(pauseButton);
        innerContainer.appendChild(rewindButton);
        innerContainer.appendChild(slowButton);
        innerContainer.appendChild(fastButton);
        innerContainer.appendChild(clearButton);

        onReady();
        return divContainer;
      },
      onRemove: () => {
        try {
          if (trackplayback) {
            trackplayback.stop();
            trackplayback.dispose();
          }
        } catch (err) {
          //
        }
      },
    });

    const pc = new playControl();
    return pc;
  }
  return null;
};

export default getControlInstance;
